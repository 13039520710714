import getData from "@/__main__/get-data.mjs";
import { saveToAllTips } from "@/game-val/actions.mjs";
import { getAgentInsights, getTips } from "@/game-val/api.mjs";
import fetchConstants, {
  networkOptions,
} from "@/game-val/fetch-constants-data.mjs";
import {
  getGuideKeyStr,
  getParams,
  getParamsObjForAgent,
} from "@/game-val/guides-utils.mjs";
import { AgentInsightsModel } from "@/game-val/models/agents.mjs";
import { TipsCollectionModel } from "@/game-val/models/tips.mjs";

export default async function fetchData(tab, searchParams) {
  const [agent] = tab;
  const [, agents] = await fetchConstants();
  const filteredAgents = agents.filter((o) => !o.key.includes("/"));
  const agentMeta = filteredAgents.find((a) => a.key === agent);

  if (agentMeta) {
    await getData(
      getAgentInsights(agentMeta.sys.id),
      AgentInsightsModel,
      ["val", "cms", "agentInsights", agentMeta.sys.id],
      networkOptions,
    );
  }

  if (tab) {
    const { side, difficulty, map } = getParams(searchParams);
    const { items } = await getData(
      getTips({
        limit: 16,
        skip: 0,
        side,
        difficulty,
        agent: agentMeta.key,
        map,
      }),
      TipsCollectionModel,
      [
        "val",
        "tips",
        "list",
        getGuideKeyStr(getParamsObjForAgent(searchParams, agent), true),
      ],
      {
        shouldFetchIfPathExists: true,
        mergeFn(current, next) {
          return {
            ...next,
            items: [...current.items, ...next.items],
          };
        },
      },
    );
    saveToAllTips(items);
  }
  return filteredAgents;
}
